import React, { useState } from 'react';
import { Button, Col, Container, FormControl, Image, InputGroup, Row, Form, Modal } from 'react-bootstrap';
import signupTo from "../../Assets/images/home/signup-to.webp";
import { base_url, client_name, currency, currencycode, helmet_url, postHeader } from '../../Helpers/request';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import Loader from '../../Components/Loader';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaFacebook, FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa';

function SignupTo() {
    const history = useHistory();
    const [newsletteremail, setNewsletteremail] = useState("")
    const [loader, setLoader] = useState(false)
    const newsletter = (e) => {
        e.preventDefault();
        setLoader(true)
        const data = {
            currency_code: currencycode,
            email: newsletteremail
        }
        axios.post(base_url + '/common/subscribe_newsletter', data, {
            headers: postHeader
        })
            .then(res => {
                if (res.data.status == 1) {
                    setLoader(false)
                    setNewsletteremail('');
                    return swal(res.data.message, "", "success");
                } else {
                    setLoader(false)
                    return swal(res.data.message, "", "error");
                }
            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
            });
    }
    return (
        <>
        
            <section className="rcs_signup_to_section">
                <Container className='ddov_cutome_container'>
                    <Row>
                        <Col lg={6} xs={12} className='border-right'>
                            <div className="rcs_signup_to_left_main">
                                <div className="rcs_signup_to_title">
                                    {/* <h2> sign up to be a <br /> {client_name.c_name} insider </h2> */}
                                    <h2>Subscribe To Our Newsletter</h2>
                                    <p className='text-center' >Sign-up for exclusive news, offers and monthly savings.</p>
                                </div>
                                <div className="rcs_signup_to_input_field">
                                    <Form className="gs_news_form w-100" onSubmit={newsletter}>
                                        <InputGroup className="mb-3">
                                            <FormControl
                                                placeholder="Email Address..."
                                                aria-label="Recipient's username"
                                                type="email"
                                                value={newsletteremail}
                                                required
                                                onChange={(e) => setNewsletteremail(e.target.value)}
                                            />
                                            <Button variant="outline-secondary" type="submit" id="button-addon2">
                                                Subscribe
                                            </Button>
                                        </InputGroup>
                                    </Form>
                                    {/* <p>By signing up you confirm that you have read the <a href="/privacy-policy">Privacy Policy</a> and agree that your email
                                        will be collected and used for the purposes of sending news, promotions and updates via email. You can withdraw your consent at any time by unsubscribing.</p> */}
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} xs={12} className="ml-auto pr-0 p-0">
                            <div className="rcs_signup_to_title">
                                <h2>Follow Us</h2>
                                <p className='text-center' >Follow us to stay up to date with Diamond Doves!</p>
                            </div>
                            <div className='ddov_social_icons'>
                                <a aria-label='Facebook' href='https://www.facebook.com/people/Diamond-Doves/100063574392240/' target='_blank' className='ddov_social_icons_inner fb'>
                                    <FaFacebookF />
                                </a>
                                <a aria-label='Instagram' href='https://www.instagram.com/diamonddoveslondon/?igshid=MzRlODBiNWFlZA%3D%3D' target='_blank' className='ddov_social_icons_inner insta'>
                                    <FaInstagram />
                                </a>
                                <a aria-label='Tiktok' href="https://www.tiktok.com/@diamonddoves?_t=8dVwZ4nwEgt&_r=1" target='_blank' className='ddov_social_icons_inner tiktok'>
                                    <FaTiktok />
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Modal show={loader}>
                <Loader />
            </Modal>
        </>
    );
}


export default SignupTo;
