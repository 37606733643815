import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import Solitaire from '../../Assets/images/engagement/solitaire_ring.webp';
// import Pave from '../../Assets/images/engagement/pave_ring.webp';
// import Halo from '../../Assets/images/engagement/halo_ring.webp';
// import Vintage from '../../Assets/images/engagement/vintage_ring.webp';
// import ThreeStone from '../../Assets/images/engagement/three_stone_ring.webp';
// import ChannelSet from '../../Assets/images/engagement/single_row_ring.webp';
// import Multirow from '../../Assets/images/engagement/multirow_ring.webp';
// import Bypass from '../../Assets/images/engagement/bypass_ring.webp';
// import SideStone from '../../Assets/images/engagement/side_stone_ring.webp';
// import other from '../../Assets/images/engagement/trellis_ring.webp';
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import princess from '../../Assets/images/diamond-doves-img/diamond-shap/princess-diamond.png'
import cushion from '../../Assets/images/diamond-doves-img/diamond-shap/cushion-diamond.png'
import emerald from '../../Assets/images/diamond-doves-img/diamond-shap/emerald-diamond.png'
import oval from '../../Assets/images/diamond-doves-img/diamond-shap/oval-diamond.png'
import round from '../../Assets/images/diamond-doves-img/diamond-shap/round-diamond.png'
import radiant from '../../Assets/images/diamond-doves-img/diamond-shap/radiant-diamond.png'
import asscher from '../../Assets/images/diamond-doves-img/diamond-shap/asscher-diamond.png'
import marquise from '../../Assets/images/diamond-doves-img/diamond-shap/marquise-diamond.png'
import heart from '../../Assets/images/diamond-doves-img/diamond-shap/heart-diamond.png'
import pear from '../../Assets/images/diamond-doves-img/diamond-shap/pear-diamond.png'
import { Link } from 'react-router-dom/cjs/react-router-dom';

const RingStyle = () => {
    var history = useHistory();

    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    var settingsV2 = {
        dots: false,
        arrows: true,
        centerMode: true,
        // infinite: true,
        autoplay: false,
        autoplaySpeed: 2000,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const shapesImagess = [
        {
            img: round,
            name: 'Round',
            tittle: 'Timeless Beauty of the Classic Round Shape Diamond.',
            url: "Round"
        },
        {
            img: princess,
            name: 'Princess',
            tittle: 'Elegant Sparkle of the Princess Shape Diamond.',
            url: "Princess"
        },
        {
            img: cushion,
            name: 'Cushion',
            tittle: 'Cushion Shape Diamond\'s Soft Elegance.',
            url: "Cushion"
        },
        {
            img: emerald,
            name: 'Emerald',
            tittle: 'Emerald Shape Diamond\'s Sleek Sophistication.',
            url: "Emerald"
        },
        {
            img: oval,
            name: 'Oval',
            tittle: 'Graceful Charm of the Oval Shape Diamond.',
            url: "Oval"
        },

        {
            img: radiant,
            name: 'Radiant',
            tittle: 'Radiant Shape Diamond\'s Bold Brilliance.',
            url: "Radiant"
        },
        {
            img: asscher,
            name: 'Asscher',
            tittle: 'Asscher Shape Diamond\'s Vintage Allure.',
            url: "Asscher"
        },
        {
            img: marquise,
            name: 'Marquise',
            tittle: 'Marquise Shape Diamond\'s Regal Elegance.',
            url: "Marquise"
        },
        {
            img: heart,
            name: 'Heart',
            tittle: 'Romantic Appeal of the Heart Shape Diamond.',
            url: "Heart"
        },
        {
            img: pear,
            name: 'Pear',
            tittle: 'Pear Shape Diamond\'s Graceful Teardrop Design.',
            url: "Pear"
        },
    ]

    const options = {
        margin: 0,
        loop: true,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: true,
        center: true,
        navText: ['&#10229;', '&#10230;'],
        smartSpeed: 1000,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 3,
            },
            1000: {
                items: 5,

            },
            1100: {
                items: 8.9,

            }
        },
    };



    // const shopbystyle = (value) => {
    //     var data1 = {
    //         style: value,
    //     }
    //     sessionStorage.setItem("bw_s_filter", JSON.stringify(data1));
    //     history.push('/ringsettings/diamonds');
    // }

    const shopbyshape = (value) => {
        var data1 = {
            shape: value,
        }
        sessionStorage.setItem("bw_d_filter", JSON.stringify(data1));
        history.push('/diamonds');
        window.scrollTo(0, 0);
        console.log(value);
    }


    return (
        <>
            <Container className='ddov_cutome_container mt-3' >
                <div className='rcs_ringstyle rcs_ringstyle_v2'>
                    <Row>
                        <Col lg={12} xs={12}>
                            <div className='rcs_ringstyle_title'>
                                <h2> Shop Diamonds By Shape </h2>
                                <p>The Art of Geometry: Exploring Exquisite Diamond Shapes.</p>
                            </div>
                        </Col>
                    </Row>

                    {/* <Slider {...settingsV2}>
                        <div className='rcs_ringstyle_content_box '>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage src={Solitaire} alt='Solitaire' onClick={() => shopbystyle('Solitaire')}/>
                            </div>
                            <h2 onClick={() => shopbystyle('Solitaire')}>Solitaire</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Pave')} src={Pave} alt='Pave'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Pave')}>Pave</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Halo')} src={Halo} alt='Halo'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Halo')}>Halo</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Three stone')} src={ThreeStone} alt='Three Stone'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Three stone')}>Three Stone</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Channel Set')} src={ChannelSet} alt='Channel Set'/>

                            </div>
                            <h2 onClick={() => shopbystyle('Channel Set')}>Channel Set</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Multirow')} src={Multirow} alt='Multirow'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Multirow')}>Multirow</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Bypass')} src={Bypass} alt='Bypass'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Bypass')}>Bypass</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Side stone')} src={SideStone} alt='Side-stone'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Side stone')}>Side Stone</h2>
                        </div>

                    </Slider> */}

                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <div className='ddov_Shop_by_shap_slider'>
                                <OwlCarousel
                                    className='owl-theme'
                                    {...options}
                                >
                                    {shapesImagess.map((item, ind) => {
                                        return <div>
                                            <div className='ddov_shap_outer_box' onClick={() => shopbyshape(item.url)}>
                                                <div className='item'>
                                                    <Image src={item.img} alt={item.name} />
                                                </div>
                                                <div className='ddov_shap_textbox_on_active'>
                                                    <h3>{item.name}</h3>
                                                    <p>{item.tittle}</p>
                                                    {/* <Button onClick={() => shopbyshape(item.url)}> Shop </Button> */}
                                                </div>

                                            </div>

                                        </div>

                                    })
                                    }
                                </OwlCarousel>
                            </div>
                        </Col>
                    </Row>

                </div>


            </Container >
        </>
    );
}

export default RingStyle;